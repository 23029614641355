//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'AdvantageList',
  props: {
    headline: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    advantages: {
      type: Array,
      default: null
    },
    buttons: {
      type: Array,
      default: () => []
    }
  }
})
